<template>
  <div>
    <v-row>
      <v-col>
        <v-card :loading="loading" elevation="0" class="pa-0 ma-0">
          <h1 v-if="chartParams && chartParams.title">
            {{ chartParams.title }}
          </h1>
        </v-card>
      </v-col>
      <v-spacer />
      <v-col style="text-align: right">
        <v-btn depressed color="primary" @click="openNewInvoiceDialog">
          Add Company
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <template v-if="filters">
        <v-col v-for="(item, index) in filters" :key="index">
          <v-combobox
            v-if="item.type && item.type === 'combobox'"
            v-model="item.value"
            :items="item.list"
            :label="item.label"
            :item-text="item.itemText"
            :item-value="item.itemValue"
            :return-object="false"
            clearable
            @change="updateValues"
          />
          <v-select
            v-else
            v-model="item.value"
            :items="item.list"
            :label="item.label"
            :item-text="item.itemText"
            :item-value="item.itemValue"
            :return-object="false"
            clearable
            @change="updateValues"
          />
        </v-col>
      </template>
      <v-col>
        <date-range-picker
          ref="picker"
          v-model="picker"
          class="mt-2"
          :single-date-picker="false"
          :show-week-numbers="false"
          :show-dropdowns="true"
          :auto-apply="false"
          @update="updateValues"
        >
          <template #input="picker" style="min-width: 350px">
            {{ picker.startDate | date }} - {{ picker.endDate | date }}
          </template>
        </date-range-picker>
      </v-col>

      <v-spacer />
      <v-col style="text-align: right"> </v-col>
    </v-row>

    <v-row>
      <v-col cols="8"> 
         <v-row>
          <v-col cols="4">
            <v-card
              class="pa-3"
              style="cursor: pointer"
              color="primary"
              :dark="true"
            >
              <v-card-title class="pa-2">
                <div class="subtitle-2">VAT Collected On Sales</div>
              </v-card-title>
              <v-divider />
              <v-card-subtitle class="mt-2">
                <h3>
                  {{ 1234555.56 | currency("₦") }}
                </h3>
              </v-card-subtitle>
            </v-card>
          </v-col>
          <v-col cols="4">
            <v-card
              class="pa-3"
              style="cursor: pointer"
              color="blue"
              :dark="true"
            >
              <v-card-title class="pa-2">
                <div class="subtitle-2">VAT Paid On Purchases</div>
              </v-card-title>
              <v-divider />
              <v-card-subtitle class="mt-2">
                <h3>
                  {{ 58930.25 | currency("₦") }}
                </h3>
              </v-card-subtitle>
            </v-card>
          </v-col>
          <v-col cols="4">
            <v-card
              class="pa-3"
              style="cursor: pointer"
              color="green"
              :dark="true"
            >
              <v-card-title class="pa-2">
                <div class="subtitle-2">Net VAT Due</div>
              </v-card-title>
              <v-divider />
              <v-card-subtitle class="mt-2">
                <h3>
                  {{ 1175625.31 | currency("₦") }}
                </h3>
              </v-card-subtitle>
            </v-card>
          </v-col>

          <v-col cols="12">
        
        <div style="width: 100%; height: 380px" class="pt-5 pb-20">
          <div
            ref="echart"
            v-resize="onResize"
            style="width: inherit; height: 490px"
          />
        </div>
      </v-col>
        </v-row>

      </v-col>
      <v-col cols="4" >
        <v-row class="p-0">
          <v-col cols="6">
            <v-card color="white">
              <v-card-title class="card-title">
                Outstanding VAT
              </v-card-title>
              <v-card-text class="pl-5 pb-5 pr-2 pt-0">
                <v-row>
                  <v-col class="card-text"> {{ 42567879 | currency("₦") }} </v-col>
                  <v-col class="card-up-red"> +20% ↑ </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="6">
            <v-card color="white">
              <v-card-title class="card-title">
                VAT Submissions
              </v-card-title>
              <v-card-text class="pl-5 pb-5 pr-2 pt-0">
                <v-row>
                  <v-col class="card-text"> 400,346 </v-col>
                  <v-col class="card-up"> +20,450 ↑ </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="6">
            <v-card color="white">
              <v-card-title class="card-title">
                Compliance Rate
              </v-card-title>
              <v-card-text class="pl-5 pb-5 pr-2 pt-0">
                <v-row>
                  <v-col class="card-text"> 80% </v-col>
                  <v-col class="card-up"> +5.8 ↑ </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="6">
            <v-card color="white">
              <v-card-title class="card-title">
                Registered Businesses
              </v-card-title>
              <v-card-text class="pl-5 pb-5 pr-2 pt-0">
                <v-row>
                  <v-col class="card-text"> 4,000,456 </v-col>
                  <v-col class="card-up"> +12,450 ↑ </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12">
            <v-card color="white">
              <v-card-title class="card-title">
                <v-icon outlined> cloud_download </v-icon> &nbsp; Invoice Status
              </v-card-title>
          <div style="width: 100%; height: 250px" class="pt-0 pb-20">
          <div
            ref="pieChart"
            v-resize="onResize"
            style="width: inherit; height: 300px"
          />
        </div>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4">
        
      </v-col>
      
    </v-row>
    <v-row
      v-if="tableData && tableColumns"
      class="mt-0"
      style="margin-top: -50px !important"
    >
      <v-col>
        <v-card elevation="0">
          <v-card-title>
            <!-- {{ tableParams.title }} -->

            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            />
            <v-spacer />
            <v-btn class="ma-2" outlined color="primary">
              <v-icon outlined> cloud_download </v-icon> Download
            </v-btn>
          </v-card-title>
          <v-data-table
            :headers="tableColumns"
            :items="tableData"
            :search="search"
            @click:row="handleClick"
          >
            <template v-slot:[`item.i_status`]="{ item }">
              <v-chip color="green" v-if="item.i_status === 'paid'" dark>
                Paid
              </v-chip>
              <v-chip color="blue" v-if="item.i_status === 'pending'" dark>
                Pending
              </v-chip>
              <v-chip color="red" v-if="item.i_status === 'cancelled'" dark>
                Cancelled
              </v-chip>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-dialog v-model="newInvoiceDialog" scrollable max-width="1200px">
        <v-card>
          <v-card-title>
            <v-row>
              <v-col>Create Invoice</v-col>
              <v-spacer />
              <v-col style="text-align: right">
                <v-icon
                  size="40"
                  color="blue lighten-1"
                  @click="newInvoiceDialog = false"
                  >close</v-icon
                >
              </v-col>
            </v-row>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text style="height: 800px">
            <new-invoice-view
              mode="create"
              :code="invoiceNumber"
              :key="invoiceNumber"
            />
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="green darken-1"
              text
              @click="newInvoiceDialog = false"
            >
              Save
            </v-btn>
            <v-btn color="blue darken-1" text @click="newInvoiceDialog = false">
              Save And Send
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="viewInvoiceDialog" scrollable max-width="1200px">
        <v-card v-if="viewInvoice">
          <v-card-title>
            <v-row>
              <v-col
                ><h1>{{ viewInvoice.i_status }}</h1></v-col
              >
              <v-spacer />
              <v-col style="text-align: right">
                <v-icon
                  size="40"
                  color="blue lighten-1"
                  @click="viewInvoiceDialog = false"
                  >close</v-icon
                >
              </v-col>
            </v-row>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text style="height: 800px">
            <new-invoice-view
              mode="view"
              :code="viewInvoice.i_invoice_number"
              :key="viewInvoice.i_invoice_number"
            />
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="green darken-1"
              text
              @click="viewInvoiceDialog = false"
            >
              Send
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              @click="viewInvoiceDialog = false"
            >
              Print
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>
<style scoped>
.card-text {
  font-size: 22px;
}
.card-up {
  color: #43A047;
  text-align: right;
  font-size: 12px;
}
.card-up-red {
  color: #D32F2F;
  text-align: right;
  font-size: 12px;
}
</style>
<script>
import * as echarts from "echarts/dist/echarts.js";
import * as dayjs from "dayjs";

// import { transform } from "./transformer";
import { option as pieChartOption } from "./pie-transformer";
import { option as barChartOption } from "./bar-transformer";

import DateRangePicker from "vue2-daterange-picker";
// you need to import the CSS manually
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";

import ReportService from "@/services/query.service";
import NewInvoiceView from "@/views/admin/new-invoice";

// import { createNamespacedHelpers } from "vuex";

// import VueExcelXlsx from "vue-excel-xlsx";
// import Vue from "vue";
import { filterReportParams } from "../../../lib/acl.lib";

// const usersNamespace = createNamespacedHelpers("users");

// Vue.use(VueExcelXlsx);

// alert("hey");
let chart = null;
let pieChart = null;

// let today = new Date()
// today.setHours(0, 0, 0, 0)

// let yesterday = new Date()
// yesterday.setDate(today.getDate() - 1)
// yesterday.setHours(0, 0, 0, 0);

// const ranges = {
//     'Today': [today, today],
//     'Yesterday': [yesterday, yesterday],
//     // 'This month': [thisMonthStart, thisMonthEnd],
//     'This month': [new Date(today.getFullYear(), today.getMonth(), 1), new Date(today.getFullYear(), today.getMonth(), 0)],
//     'This year': [new Date(today.getFullYear(), 0, 1), new Date(today.getFullYear(), 11, 31)],
//     'Last month': [new Date(today.getFullYear(), today.getMonth() - 1, 1), new Date(today.getFullYear(), today.getMonth(), 0)],
// };

export default {
  name: "BarChart",
  components: {
    DateRangePicker,
    NewInvoiceView,
  },
  props: {
    report: {
      type: String,
      default: null,
    },
    chartParams: {
      default: null,
    },
    tableParams: {
      default: null,
    },
    filters: {
      default: null,
    },
  },
  data() {
    return {
      viewInvoice: null,
      viewInvoiceDialog: false,
      dialogm1: "",
      dialog: false,
      invoiceNumber: "FIRS-1708086626-243",
      newInvoiceDialog: false,
      search: null,
      loading: false,
      valueColumn: null,
      picker: {
        startDate: dayjs().subtract(30, "day"),
        endDate: dayjs(),
      },
      direction: "ltr",
      dateFormat: "dd/mm/yyyy",
      separator: " - ",
      applyLabel: "Apply",
      cancelLabel: "Cancel",
      weekLabel: "W",
      customRangeLabel: "Custom Range",
      daysOfWeek: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
      monthNames: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      firstDay: 0,
      chartData: [],
      columnX: "date",
      summary: [],
      startDate: null,
      endDate: null,
      tableColumns: null,
      tableData: [],
      exportColumns: [],
      filterList: {},
    };
  },
  computed: {
    snackbar: {
      get() {
        return this.$store.state.snackbar;
      },
      set() {
        this.$store.commit("mCloseAlert");
      },
    },
    message() {
      return this.$store.state.snackbarMessage;
    },
    isMobile() {
      return false;
    },
  },
  beforeCreate() {},
  mounted() {
    this.init();
    this.loadFilters();
    this.loadReport(this.picker);

    // alert("hey");
  },
  methods: {
    openNewInvoiceDialog() {
      this.newInvoiceDialog = true;
    },
    handleClick(item) {
      this.viewInvoice = { ...item };
      this.viewInvoiceDialog = true;
      // this.$emit("row-clicked", item);
    },
    export() {},
    updateValues() {
      this.resetSummary();
      // console.log("dateRange", dateRange);
      this.loadReport(this.picker);
    },
    closeAlert() {
      this.$store.commit("mCloseAlert");
    },
    init() {
      this.setDefaults();
      chart = echarts.init(this.$refs.echart);
      pieChart = echarts.init(this.$refs.pieChart);
    },
    draw() {
      if (!chart) {
        this.init();
      }

      pieChart.setOption(pieChartOption, true);
      chart.setOption(barChartOption, true);
    },
    // draw(data, options) {
    //   if (!chart) {
    //     this.init();
    //   }

    //   pieChart.setOption(pieChartOption, true);
    //   // chart.setOption(transform(data || [], options), true);
    // },
    onResize() {
      if (chart) {
        chart.resize();
      }
    },
    async loadFilters() {
      const filters = this.filters;
      const that = this;
      if (filters) {
        filters.forEach((item) => {
          try {
            if (item.source !== "list") {
              that.loadFilter(item);
            }
          } catch (err) {
            //
          }
        });
      }
    },
    async loadFilter(item) {
      const conditions = item && item.conditions ? item.conditions : {};
      const response = await ReportService.getReport(item.source, conditions);
      item.list = response.data.items;
    },
    async loadReport(dateRange) {
      // const options = {
      //   data: [],
      //   startDate: dayjs(dateRange.startDate).format("DD/MM/YYYY"),
      //   endDate: null,
      //   x: null,
      //   y1: null,
      //   y3: null,
      // };
      try {
        this.loading = true;

        const startDate = dayjs(dateRange.startDate);
        const endDate = dayjs(dateRange.endDate);

        this.startDate = startDate;
        this.endDate = endDate;

        const reportParams = {
          data: [],
          startDate: startDate.format("YYYY-MM-DD"),
          endDate: endDate.format("YYYY-MM-DD"),
          // "column.x": "date",
          x: null,
          y1: null,
          y3: null,
        };

        const diff = endDate.diff(startDate, "day");
        const columnX = diff <= 100 ? "date" : "month";
        this.columnX = columnX;
        reportParams["column.x"] = columnX;

        // this.chartParams[columnX] = columnX;
        // const chartParams = {
        //   x: "x",
        //   valueColumn: "to_amount",
        //   labelColumn: "branch_name",
        // };

        // const tableConditions =
        //   this.tableParams && this.tableParams.conditions
        //     ? this.tableParams.conditions
        //     : {};
        // const chartConditions =
        //   this.chartParams && this.chartParams.conditions
        //     ? this.chartParams.conditions
        //     : {};

        const tableParams = this.tableParams;
        this.tableColumns = tableParams.valueColumns;

        this.loadTable(this.chartParams);

        // const response = await ReportService.sql(
        //   this.report,
        //   this.appendFilters({ ...chartConditions, ...reportParams })
        // );
        // console.log("response.data", response.data);

        // this.chartData = response.data.items;

        // this.setSummary();
        this.redraw();
      } catch (error) {
        //
        console.log("error", error);
      }
      this.loading = false;
    },
    appendFilters(params) {
      if (this.filters) {
        this.filters.forEach((item) => {
          if (item.column && item.value) {
            params[item.column] = item.value;
          }
        });
      }
      return params;
    },
    async loadTable() {
      try {
        this.loadingTable = true;
        // const sql = `SELECT * from invoices where business_code = '${params.businessCode}'`;
        const sql = `SELECT 
        i.id as i_id, 
        i.business_code as i_business_code, 
        i.invoice_number as i_invoice_number, 
        i.amount as i_amount, 
        i.currency as i_currency, 
        i.vat_amount as i_vat_amount, 
        i.invoiced_to as i_invoiced_to, 
        i.status as i_status, 
        i.created_date as i_created_date, 
        i.paid_date as i_paid_date, 
        i.description as i_description,
        b.id as b_id, 
        b.code as b_code, 
        b.name as b_name, 
        b.vat_number as b_vat_number, 
        b.email as b_email, 
        b.phone_number as b_phone_number, 
        b.address_1 as b_address_1, 
        b.address_2 as b_address_2, 
        b.city as b_city, 
        b.country as b_country
        FROM invoices i 
        left join business b on b.code = i.invoiced_to`;


        const payload = {
          sql,
        };
        const response = await ReportService.sql(payload);
        this.tableData = response.data;
      } catch (error) {
        //
        console.log(error);
      }
      this.loadingTable = false;
    },
    redraw() {
      // const chartParams = { ...this.chartParams };

      // chartParams.valueColumn = this.valueColumn;
      // chartParams.startDate = this.startDate;
      // chartParams.endDate = this.endDate;
      // chartParams.dateLevel = this.columnX === "date" ? "day" : "month";

      this.draw();
    },
    resetSummary() {
      if (!this.summary) {
        return;
      }
      const summary = [...this.summary];
      summary.map((item) => {
        item.displayValue = 0;
        return item;
      });
      this.summary = summary;
    },
    setSummary() {
      const chartParams = filterReportParams(this.chartParams, this.user);

      const summary = [...chartParams.valueColumns];
      const chartData = [...this.chartData];

      if (chartData.length) {
        const firstValue = chartData[0];
        summary.forEach((item) => {
          item.displayValue = chartData.reduce(
            (total, obj) => (obj[item.value] ? obj[item.value] : 0) + total,
            0
          );
          item.currency =
            item.currencyKey && firstValue[item.currencyKey]
              ? firstValue[item.currencyKey]
              : null;
        });
      }
      this.summary = summary;
    },
    setDefaults() {
      this.valueColumn = this.chartParams.valueColumn;
    },
    updateValueColumn(valueColumn) {
      this.valueColumn = valueColumn;
      this.redraw();
    },
  },
};
</script>
