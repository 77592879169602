// const config = {
// 	rotate: 90,
// 	align: "left",
// 	verticalAlign: "middle",
// 	position: "insideBottom",
// 	distance: 15
// };
// const labelOption = {
// 	show: true,
// 	position: config.position,
// 	distance: config.distance,
// 	align: config.align,
// 	verticalAlign: config.verticalAlign,
// 	rotate: config.rotate,
// 	formatter: "{c}  {name|{a}}",
// 	fontSize: 16,
// 	rich: {
// 		name: {}
// 	}
// };
export const option = {
	tooltip: {
		trigger: "axis",
		axisPointer: {
			type: "shadow"
		}
	},
	legend: {
		data: ["Paid", "Pending", "Cancelled"]
	},
	toolbox: {
		show: true,
		orient: "vertical",
		left: "right",
		top: "center",
		feature: {
			mark: { show: true },
			dataView: { show: true, readOnly: false },
			magicType: { show: true, type: ["line", "bar", "stack"] },
			restore: { show: true },
			saveAsImage: { show: true }
		}
	},
	xAxis: [
		{
			type: "category",
			axisTick: { show: false },
			data: ["May 2023", "Jun 2023", "Jul 2023", "Aug 2023", "Oct 2023", "Sept 2023", "Oct 2023", "Nov 2023", "Dec 2023", "Jan 2024"]
		}
	],
	yAxis: [
		{
			type: "value"
		}
	],
	series: [
		{
			name: "Paid",
			type: "bar",
			barGap: 0,
			// label: labelOption,
			itemStyle: {color: '#4CAF50'},
			emphasis: {
				focus: "series"
			},
			data: [150, 232, 201, 154, 190, 3200, 3320, 3010, 3340, 3900]
		},
		{
			name: "Pending",
			type: "bar",
			itemStyle: {color: '#2196F3'},
			// label: labelOption,
			emphasis: {
				focus: "series"
			},
			data: [220, 182, 191, 234, 290, 220, 182, 191, 234, 290]
		},
		{
			name: "Cancelled",
			type: "bar",
			itemStyle: {color: '#F44336'},
			// label: labelOption,
			emphasis: {
				focus: "series"
			},
			data: [220, 182, 191, 234, 290, 150, 232, 201, 154, 190]
		},
	]
};
